import React from 'react';
import imageO from '../../assets/images/imageO.jpg';
import planetaryMixe from '../../assets/images/60-ltr-planetary-mixer-500x500.webp';
import PlanetaryMixerBowl from '../../assets/images/PlanetaryMixerBowl.png';


const WeHelpSection = () => {
  return (
    <div className="we-help-section">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-7 mb-5 mb-lg-0">
            <div className="imgs-grid">
              <div className="grid grid-1"><img src={imageO} alt="Untree.co" /></div>
              <div className="grid grid-2"><img src={PlanetaryMixerBowl} alt="Untree.co" /></div>
              <div className="grid grid-3"><img src={planetaryMixe} alt="Untree.co" /></div>
            </div>
          </div>
          <div className="col-lg-5 ps-lg-5">
            <h2 className="section-title mb-4">About Us</h2>
            <p>We, AS Machine, are enlisted amongst the renowned manufacturers of an exceptional quality collection of Bakery Oven , Bread Slicer Machine,and Mixer Machine. The whole provided range of products is manufactured by our adroit professionals utilizing supreme quality components and upgraded techniques. Furthermore, these products are strictly examined against varied parameters of quality to ensure the delivery of fault free products.</p>
            <p><b>Some other features of our company are:</b></p>
            <ul className="list-unstyled custom-list my-4">
              <li>Ultra-modular infrastructure </li>
              <li>Transparent dealings</li>
              <li>Simple payment modes</li>
              <li>Competitive rates</li>
            </ul>
            <p><a href="/about" className="btn">Explore</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};



export default WeHelpSection;
