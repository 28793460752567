import React from 'react';
import '../assets/css/tiny-slider.css';
import WhyChooseUsSection from './Component/WhyChooseUsSection';
import WeHelpSection from './Component/WeHelpSection';
import PopularProducts from './Component/PopularProducts';
import TestimonialSlider from './Component/TestimonialSlider';
import TopSection from './Component/TopSection';
import ProductSection from './Component/ProductSection';

export default function Home() {
  return (
    <div>
      <TopSection />

      <ProductSection />

      <WhyChooseUsSection />

      <WeHelpSection />

      <PopularProducts />

      <TestimonialSlider />

      
    </div>
  );
}
