import React from 'react';

export default function Cross({className}) {
  return (
    <svg className={className} width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4Z" stroke="white" strokeWidth="2" />
      <path d="M2 4L12 12L22 4" stroke="white" strokeWidth="2" />
      <path d="M2 20L12 12L22 20" stroke="white" strokeWidth="2" />
    </svg>
  );
}
