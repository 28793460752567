import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import Cart from '../assets/images/Svg/cart';
import User from '../assets/images/Svg/user';
import '../assets/css/style.css';

function Navigation() {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };

    return (
        <nav className="custom-navbar navbar navbar-expand-md navbar-dark bg-dark" aria-label="Furni navigation bar">
            <div className="container">
                <a className="navbar-brand" href="/">AS Machines<span>.</span></a>

                <button 
                    className="navbar-toggler" 
                    type="button" 
                    onClick={handleToggle}
                    aria-controls="navbarsFurni" 
                    aria-expanded={isOpen} 
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarsFurni">
                    <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                                to="/"
                                onClick={handleLinkClick}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                                to="/about"
                                onClick={handleLinkClick}
                            >
                                About us
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                                to="/services"
                                onClick={handleLinkClick}
                            >
                                Category
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                                to="/products"
                                onClick={handleLinkClick}
                            >
                                Our Products
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                                to="/contact"
                                onClick={handleLinkClick}
                            >
                                Contact us
                            </NavLink>
                        </li>
                    </ul>

                    <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
                        {/* Uncomment these for user and cart icons */}
                        {/* <li><NavLink className="nav-link" to="#"><User /></NavLink></li> */}
                        {/* <li><NavLink className="nav-link" to="cart.html"><Cart /></NavLink></li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;
