import React from 'react';
import DotsLight from '../../assets/images/Svg/DotsLight';
import DeckovenbakeryImage from '../../assets/images/deckovenbakery.png';

function TopSection() {
  return (
    <div className="hero">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="intro-excerpt">
              <h1>Modern Machines<span className="d-block">for Timeless Taste</span></h1>
              <p className="mb-4">Our bakery machines combine precision engineering with advanced technology, ensuring perfect results every time. Elevate your baking with automation designed for consistency and quality.</p>
              <p>
                <a href="/contact" className="btn btn-secondary me-2">Contact Us</a>
                <a href="/services" className="btn btn-white-outline">Explore</a>
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="hero-img-wrap">
              <img src={DeckovenbakeryImage} className="img-fluid" alt="Couch" />
              <DotsLight  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopSection; 
