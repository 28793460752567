import React, { useEffect, useRef } from 'react';
import { tns } from 'tiny-slider/src/tiny-slider'; // Make sure to import the Tiny Slider
import person1 from '../../assets/images/person-1.png';
import person2 from '../../assets/images/person_2.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function TestimonialSlider() {
  const sliderRef = useRef(null); // Create a reference for the slider

  useEffect(() => {
    // Initialize the Tiny Slider
    const slider = tns({
      container: sliderRef.current,
      items: 1,
      axis: "horizontal",
      controlsContainer: "#testimonial-nav",
      swipeAngle: false,
      speed: 700,
      nav: true,
      controls: true,
      autoplay: true,
      autoplayHoverPause: true,
      autoplayTimeout: 3500,
      autoplayButtonOutput: false,
    });

    // Cleanup on component unmount
    return () => {
      slider.destroy(); // Destroy the slider instance on unmount
    };
  }, []);

  return (
    // Start Testimonial Slider
    <div className="testimonial-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mx-auto text-center">
            <h2 className="section-title">Testimonials</h2>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="testimonial-slider-wrap text-center">
              <div id="testimonial-nav">
                  <span className="prev" data-controls="prev">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
                 <span className="next" data-controls="next">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
              </div>

              <div className="testimonial-slider" ref={sliderRef}>
                <div className="item">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 mx-auto">
                      <div className="testimonial-block text-center">
                        <blockquote className="mb-5">
                          <p>&ldquo;AS Machines has transformed our bakery operations with their innovative machines. The planetary mixer we purchased has significantly reduced our mixing time while enhancing the consistency of our dough. The team at AS Machines provided exceptional support during installation and training. I highly recommend their services to anyone looking to elevate their baking process!.&rdquo;</p>
                        </blockquote>

                        <div className="author-info">
                          <div className="author-pic">
                            <img src={person1} alt=" Anita Sharma" className="img-fluid" />
                          </div>
                          <h3 className="font-weight-bold"> Anita Sharma</h3>
                          <span className="position d-block mb-3">Owner of Sweet Delights Bakery.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* END item */}

                {/* Repeat similar structure for more testimonials */}
                <div className="item">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 mx-auto">
                      <div className="testimonial-block text-center">
                        <blockquote className="mb-5">
                          <p>&ldquo;We have been using AS Machines for our bakery needs for over a year now, and we couldn’t be happier. Their bakery ovens deliver even baking results, and the bread slicer machine has made our production much more efficient. The quality of their machines and the customer service is outstanding. AS Machines is a reliable partner for any bakery!&rdquo;</p>
                        </blockquote>

                        <div className="author-info">
                          <div className="author-pic">
                            <img src={person2} alt="Maria Jones" className="img-fluid" />
                          </div>
                          <h3 className="font-weight-bold">Rajesh Gupta</h3>
                          <span className="position d-block mb-3"> Head Chef at Artisan Breads.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialSlider; 