import React from 'react';
import PotatoPeelerMachine from '../../assets/images/PotatoPeelerMachine.png';
import SingleBreadSlicer from '../../assets/images/singlebreadslicer.png';
import DoubleArm from '../../assets/images/DoubleArm.png';
import Traybakery from '../../assets/images/Traybakery.png';
import Planterymixermachine from '../../assets/images/top.png';
import PlanetaryMixerBowl from '../../assets/images/PlanetaryMixerBowl.png';

const PopularProducts = () => {
  return (
    <div className="popular-product">
      <div className="container">
        <div className="row">
          {[
            {
              Url: "bakery-oven",
              imgSrc: Traybakery,
              title: 'Bakery Oven',
              description: 'Bake perfect bread and pastries with our high-efficiency bakery oven, designed for consistent results.',
            },
           
            {
              Url: "BreadSlicerMachine",
              imgSrc: SingleBreadSlicer,
              title: 'Bread Slicer Machine',
              description: 'Slice bread evenly and effortlessly with our automatic bread slicer, perfect for any bakery.',
            },
            {
              Url: "PlanetaryMixerBowl",
              imgSrc: PlanetaryMixerBowl,
              title: 'Planetary Mixer Bowl',
              description: 'Complement your mixer with our durable planetary mixer bowl, designed for optimal mixing.',
            },
            {
              Url: "MixerMachine",
              imgSrc: DoubleArm,
              title: 'Mixer Machine',
              description: 'Experience versatile mixing capabilities with our powerful mixer machine, perfect for any kitchen.',
            },
            {
              Url: "PotatoPeelerMachine",
              imgSrc: PotatoPeelerMachine,
              title: 'Potato Peeler Machine',
              description: 'Save time and labor with our efficient potato peeler machine, designed for fast and clean peeling.',
            },
            {
              Url: "planetary-mixer",
              imgSrc: Planterymixermachine,
              title: 'Planetary Mixer',
              description: 'Achieve flawless mixes with our planetary mixer, ideal for dough, batter, and more.',
            },
          ].map((product, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="product-item-sm d-flex" style={{ marginBottom: '50px' }}>
                <div className="thumbnail">
                  <img src={product.imgSrc} alt={product.title} className="img-fluid" />
                </div>
                <div className="pt-3">
                  <h3>{product.title}</h3>
                  <p>{product.description}</p>
                  <p><a href={`service/${product.Url}`}>Read More</a></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

  );
};


export default PopularProducts;
