import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import PlanetaryMixerBowl1 from '../../../assets/images/Service/PlanetaryMixerBowl/3.webp';
import PlanetaryMixerBowl2 from '../../../assets/images/Service/PlanetaryMixerBowl/1.webp';
import PlanetaryMixerBowl3 from '../../../assets/images/Service/PlanetaryMixerBowl/2.webp';


function PlanetaryMixerBowl() {
    const [selectedImages, setSelectedImages] = useState({});

    const handleImageClick = (id, image) => {
        setSelectedImages((prev) => ({ ...prev, [id]: image }));
    };

    const AllOvens = [
        {
            id: "1",
            images: [PlanetaryMixerBowl1, PlanetaryMixerBowl2, PlanetaryMixerBowl3],
            productDetails: {
                name: "10 Ltr Planetary Mixer Imported",
                price: "₹ 28,500",
                Material: "Mild Steel",
                MixerType: "Single",
                BowlVolume: "10 Litre",
                PowerSource: "Electric",
                UsageApplication: "Bakery/Commercial",
                AutomationGrade: "Semi-Automatic",
                Capacity: "10 kg",
                UsedforMaking: "Biscuit/Cookies",
                description: "Cast iron body Stainless Steel Contacts PartsWe, AS Machine, are enlisted amongst the renowned manufacturers of an exceptional quality collection of Bakery Oven , Bread Slicer Machine,and Mixer Machine. The whole provided range of products is manufactured by our adroit professionals utilizing supreme quality components and upgraded techniques. Furthermore, these products are strictly examined against varied parameters of quality to ensure the delivery of fault free products.",
                Warranty: "1 Year",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        },
    ];

    return (
        <div className="untree_co-section before-footer-section">
            <div className="container">
                {AllOvens.map((AllOven) => {
                    const { id, images, productDetails } = AllOven;
                    const selectedImage = selectedImages[id] || images[0];

                    return (
                        <div key={id} className="row mb-5">
                            <div className="col-md-6 left-side">
                                <div className="image-layout">
                                    <Swiper
                                        direction="vertical"
                                        spaceBetween={10}
                                        slidesPerView={Math.min(AllOven.images.length, 5)}
                                        loop={true}
                                        className="image-slider"
                                        breakpoints={{
                                            768: {
                                                direction: 'vertical',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            576: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            0: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                        }}
                                    >
                                        {images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={image}
                                                    alt={`Oven ${index + 1}`}
                                                    className="small-image"
                                                    onClick={() => handleImageClick(id, image)}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="big-image-container">
                                        <img src={selectedImage} alt="Selected Bakery Oven" className="big-image" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 right-side">
                                <h2>{productDetails.name}</h2>
                                <hr />
                                <div className="row mt-3 productDetails">
                                    <div className="col-md-6">
                                        {productDetails?.price && (
                                            <p><strong>Price:</strong> {productDetails.price}</p>
                                        )}
                                        {productDetails?.Material && (
                                            <p><strong>Material:</strong> {productDetails.Material}</p>
                                        )}

                                        {productDetails?.MixerType && (
                                            <p><strong>Mixer Type	:</strong> {productDetails.MixerType}</p>
                                        )}
                                        {productDetails?.BowlVolume && (
                                            <p><strong>Bowl Volume	:</strong> {productDetails.BowlVolume}</p>
                                        )}
                                        {productDetails?.UsageApplication && (
                                            <p><strong>Usage Application	:</strong> {productDetails.UsageApplication}</p>
                                        )}
                                        {productDetails?.Capacity && (
                                            <p><strong>Capacity :</strong> {productDetails.Capacity}</p>
                                        )}

                                    </div>
                                    <div className="col-md-6">
                                        {productDetails?.PowerSource && (
                                            <p><strong>Power Source :</strong> {productDetails.PowerSource}</p>
                                        )}

                                        {productDetails?.AutomationGrade && (
                                            <p><strong>Automation Grade :</strong> {productDetails.AutomationGrade}</p>
                                        )}
                                        {productDetails?.UsedforMaking && (
                                            <p><strong>Used for Making :</strong> {productDetails.UsedforMaking}</p>
                                        )}
                                        {productDetails?.Warranty && (
                                            <p><strong>Warranty :</strong> {productDetails.Warranty}</p>
                                        )}

                                        {productDetails?.Brand && (
                                            <p><strong>Brand:</strong> {productDetails.Brand}</p>
                                        )}
                                        {productDetails?.CountryofOrigin && (
                                            <p><strong>Country of Origin:</strong> {productDetails.CountryofOrigin}</p>
                                        )}
                                    </div>
                                    {productDetails?.description && (
                                        <p><strong>Description:</strong> {productDetails.description}</p>
                                    )}

                                </div>
                                <hr />
                                <div className="contact-section mt-1 text-end">
                                    <a href="tel:+9837822217" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-phone me-2"></i>Call Us: 9837822217
                                    </a>
                                    <a href="mailto:sainirmi143@gmail.com" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-envelope me-2"></i>sainirmi143@gmail.com
                                    </a>
                                    <a href='/contact' className='btn btn-primary me-2'>Contact Us</a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default PlanetaryMixerBowl;