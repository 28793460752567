import React from 'react';

export default function Return() {
  return (
    <svg 
      width="46" 
      height="46" 
      viewBox="0 0 46 46" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M16.3333 37.3333L11 32L16.3333 26.6666" 
        stroke="black" 
        strokeWidth="2.66667" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M35 23.9999V26.6665C35 28.081 34.4381 29.4376 33.4379 30.4378C32.4377 31.438 31.0812 31.9999 29.6667 31.9999H11" 
        stroke="black" 
        strokeWidth="2.66667" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M29.6667 7.99988L35 13.3332L29.6667 18.6665" 
        stroke="black" 
        strokeWidth="2.66667" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M11 21.3333V18.6666C11 17.2521 11.5619 15.8955 12.5621 14.8953C13.5623 13.8952 14.9188 13.3333 16.3333 13.3333H35" 
        stroke="black" 
        strokeWidth="2.66667" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
}
