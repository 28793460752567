import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import BakeryOvenImage from '../../../assets/images/Service/BakeryOven.png';
import RotaryRackOven1 from '../../../assets/images/Service/BekaryOven/1.webp';
import RotaryRackOven2 from '../../../assets/images/Service/BekaryOven/2.webp';
import RotaryRackOven3 from '../../../assets/images/Service/BekaryOven/3.webp';
import RotaryRackOven4 from '../../../assets/images/Service/BekaryOven/4.webp';
import Tray84_1 from '../../../assets/images/Service/BekaryOven/84Tray1.webp';
import Tray84_2 from '../../../assets/images/Service/BekaryOven/84Tray2.webp';
import Tray84_3 from '../../../assets/images/Service/BekaryOven/84Tray3.webp';
import Tray84_4 from '../../../assets/images/Service/BekaryOven/84Tray4.webp';
import DeckOven from '../../../assets/images/Service/BekaryOven/deckOven.webp';
import Tray120_1 from '../../../assets/images/Service/BekaryOven/120Tray1.webp';
import Tray120_2 from '../../../assets/images/Service/BekaryOven/120Tray2.webp';


import './BakeryOven.css';

function BakeryOven() {
    const [selectedImages, setSelectedImages] = useState({}); // Store selected images for each oven

    const handleImageClick = (id, image) => {
        setSelectedImages((prev) => ({ ...prev, [id]: image }));
    };

    const AllOvens = [
        {
            id: "1",
            images: [BakeryOvenImage, RotaryRackOven1, RotaryRackOven2, RotaryRackOven3, RotaryRackOven4],
            productDetails: {
                name: "Rotary Rack Oven 28 Tray",
                price: "₹ 2,75,000",
                description: "Rotary rack oven is a compact, diesel fired oven with an automatic control panel. The oven is designed to accommodate a single rotating rack of up to twenty-eight trays capacity. ... 200kg Per day production",
                NumberofTray: "28",
                Type: "Automatic",
                PowerSource: "Diesel",
                Size: "Tray Size-13x18",
                UsageApplication: "Bakery",
                Capacity: "200Kg",
                DoorType: "Single Door",
                BodyMaterial: "Stainless Steel",
                Warranty: "1 Year",
                IDealIn: "New and Used",
                DesignType: "Standard",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        },
        {
            id: "2",
            images: [Tray84_1, Tray84_2, Tray84_3, Tray84_4],
            productDetails: {
                name: "Rotary Rack Oven 84 Tray",
                price: "₹ 4,45,000",
                description: "We offer to our honored patrons the first-class range of 84 Tray Bakery Oven. Furthermore, our patrons can avail this product from us at affordable rates. Note Price Range- Rs. 450000 per unit Price may vary as per product specifications",
                NumberofTray: "84",
                Type: "Semi-Automatic",
                PowerSource: "Diesel",
                Size: "Large",
                UsageApplication: "Bakery",
                Capacity: "200Kg",
                DoorType: "Single Door",
                BodyMaterial: "Stainless Steel",
                Warranty: "1 Year",
                IDealIn: "New and Used",
                DesignType: "Standard",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        },
        {
            id: "3",
            images: [DeckOven],
            productDetails: {
                name: "Deck Oven",
                price: "₹ 32,500",
                description: "1 DECK 1 TRAY GAS OVENTray size-16*24  Power-100 W Gas consumption-130g Single phase Fully automatic",
                Type: "Semi-Automatic",
                PowerSource: "Diesel",
                DesignType: "Standard",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        },
        {
            id: "4",
            images: [Tray120_1, Tray120_2],
            productDetails: {
                name: "Rotary Rack Oven 120 Tray",
                price: "₹ 6,10,300",
                ForBaking: "Bread/Bun, Biscuit/Cookies",
                description: "We offer to our honored patrons the first-class range of 84 Tray Bakery Oven. Furthermore, our patrons can avail this product from us at affordable rates. Note Price Range- Rs. 450000 per unit Price may vary as per product specifications",
                NumberofTray: "120",
                Type: "Automatic",
                PowerSource: "Diesel",
                Size: "Large",
                UsageApplication: "Bakery, Commercial, Hotels & Restaurant",
                Capacity: "100-500 Kg",
                DoorType: "Single Door",
                BodyMaterial: "Stainless Steel",
                Warranty: "1 Year",
                IDealIn: "New and Used",
                DesignType: "Standard",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        }

    ];

    return (
        <div className="untree_co-section before-footer-section">
            <div className="container">
                {AllOvens.map((AllOven) => {
                    const { id, images, productDetails } = AllOven;
                    const selectedImage = selectedImages[id] || images[0];

                    return (
                        <div key={id} className="row mb-5">
                            <div className="col-md-6 left-side">
                                <div className="image-layout">
                                    <Swiper
                                        direction="vertical"
                                        spaceBetween={10}
                                        slidesPerView={Math.min(AllOven.images.length, 5)}
                                        loop={true}
                                        className="image-slider"
                                        breakpoints={{
                                            768: {
                                                direction: 'vertical',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            576: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            0: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                        }}
                                    >
                                        {images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={image}
                                                    alt={`Oven ${index + 1}`}
                                                    className="small-image"
                                                    onClick={() => handleImageClick(id, image)}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="big-image-container">
                                        <img src={selectedImage} alt="Selected Bakery Oven" className="big-image" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 right-side">
                                <h2>{productDetails.name}</h2>
                                <hr />
                                <div className="row mt-3 productDetails">
                                    <div className="col-md-6">
                                        {productDetails?.price && (
                                            <p><strong>Price:</strong> {productDetails.price}</p>
                                        )}
                                        {productDetails?.NumberofTray && (
                                            <p><strong>Number of Tray:</strong> {productDetails.NumberofTray}</p>
                                        )}
                                        {productDetails?.ForBaking && (
                                            <p><strong>For Baking:</strong> {productDetails.ForBaking}</p>
                                        )}
                                        {productDetails?.Type && (
                                            <p><strong>Type:</strong> {productDetails.Type}</p>
                                        )}
                                        {productDetails?.PowerSource && (
                                            <p><strong>Power Source:</strong> {productDetails.PowerSource}</p>
                                        )}
                                        {productDetails?.Size && (
                                            <p><strong>Size:</strong> {productDetails.Size}</p>
                                        )}
                                        {productDetails?.UsageApplication && (
                                            <p><strong>Usage Application:</strong> {productDetails.UsageApplication}</p>
                                        )}
                                        {productDetails?.Capacity && (
                                            <p><strong>Capacity:</strong> {productDetails.Capacity}</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        {productDetails?.DoorType && (
                                            <p><strong>Door Type:</strong> {productDetails.DoorType}</p>
                                        )}
                                        {productDetails?.BodyMaterial && (
                                            <p><strong>Body Material:</strong> {productDetails.BodyMaterial}</p>
                                        )}
                                        {productDetails?.Warranty && (
                                            <p><strong>Warranty:</strong> {productDetails.Warranty}</p>
                                        )}
                                        {productDetails?.IDealIn && (
                                            <p><strong>I Deal In:</strong> {productDetails.IDealIn}</p>
                                        )}
                                        {productDetails?.DesignType && (
                                            <p><strong>Design Type:</strong> {productDetails.DesignType}</p>
                                        )}
                                        {productDetails?.Brand && (
                                            <p><strong>Brand:</strong> {productDetails.Brand}</p>
                                        )}
                                        {productDetails?.CountryofOrigin && (
                                            <p><strong>Country of Origin:</strong> {productDetails.CountryofOrigin}</p>
                                        )}
                                    </div>
                                    {productDetails?.description && (
                                        <p><strong>Description:</strong> {productDetails.description}</p>
                                    )}
                                </div>
                                <hr />
                                <div className="contact-section mt-1 text-end">
                                    <a href="tel:+9837822217" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-phone me-2"></i>Call Us: 9837822217
                                    </a>
                                    <a href="mailto:sainirmi143@gmail.com" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-envelope me-2"></i>sainirmi143@gmail.com
                                    </a>
                                    <a href='/contact' className='btn btn-primary me-2'>Contact Us</a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default BakeryOven;
