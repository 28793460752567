import React, { useState } from 'react';
import PotatoPeelerMachine from '../../assets/images/PotatoPeelerMachine.png';
import SingleBreadSlicer from '../../assets/images/singlebreadslicer.png';
import DoubleArm from '../../assets/images/DoubleArm.png';
import Traybakery from '../../assets/images/Traybakery.png';
import Planterymixermachine from '../../assets/images/top.png';
import PlanetaryMixerBowl from '../../assets/images/PlanetaryMixerBowl.png';
import CrossImage from '../../assets/images/Svg/cross';
import planetaryMixer from '../../assets/images/60-ltr-planetary-mixer-500x500.webp';
import whychoiceImage from '../../assets/images/RotryRockOvens.png';

const MAX_DESC_LENGTH = 100; 

function InnerService() {
  const [expanded, setExpanded] = useState({}); 

  const toggleDescription = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index], 
    }));
  };

  const renderDescription = (description, index) => {
    if (expanded[index]) {
      return (
        <p>{description}</p>
      );
    } else {
      return (
        <>
          <p>
            {description.length > MAX_DESC_LENGTH 
              ? `${description.substring(0, MAX_DESC_LENGTH)}...` 
              : description}
          </p>
          {description.length > MAX_DESC_LENGTH && (
            <button onClick={() => toggleDescription(index)} className="read-more-button">
              Read more
            </button>
          )}
        </>
      );
    }
  };

  const products = [
    {
      image: PotatoPeelerMachine,
      title: "Potato Peeler Machine 20kg",
      price: "₹ 44,500",
      description: "The incredibly efficient and strong potato Peeler machine is designed for continuous high-volume use and allows for the preparation of up to 20kgs of ...",
    },
    {
      image: DoubleArm,
      title: "Double Arm Mixer 100Kg",
      price: "₹ 1,05,000",
      description: "We are engaged in business of manufacturing and supplying top quality 100 kg Double Arm Mixer Machine. It is mainly used in bakery industry for producing soft dough for different bakery items such as French bread, sandwich bread, confectionery, and bread sticks. We have used premium-quality stainless steel for making of dent-prevention guard, bowl, and set of kneading tools. 100 kg Double Arm Mixer Machine has two arms that mix dough at high speed; it may be supplied with two different speeds.",
    },
    {
      image: SingleBreadSlicer,
      title: "Single Bread Slicer Machine With SS Tray",
      price: "₹ 34,500",
      description: "The bread slicer is an equipment designed to cut bakery products already prepared (cooked) into several slices automatically, replacing the manual use of kitchen utensils. It consists of: Spaced blades in several standard dimensions, proceeding to a graph cut. The cutting process is triggered by a side lever.",
    },
    {
      image: PlanetaryMixerBowl,
      title: "Planetary Mixer Bowl",
      price: "₹ 28,500",
      description: "cast iron body Stainless Steel Contacts PartsWe, AS Machine, are enlisted amongst the renowned manufacturers of an exceptional quality collection of Bakery Oven , Bread Slicer Machine,and Mixer Machine. The whole provided range of products is manufactured by our adroit professionals utilizing supreme quality components and upgraded techniques. Furthermore, these products are strictly examined against varied parameters of quality to ensure the delivery of fault free products.",
    },
    {
      image: Planterymixermachine,
      title: "7Ltr Plantery Mixer Steel Body Imported",
      price: "₹ 21,500",
      description: "07 Ltr BWe, AS Machine, are enlisted amongst the renowned manufacturers of an exceptional quality collection of Bakery Oven , Bread Slicer Machine,and Mixer Machine.",
    },
    {
      image: whychoiceImage,
      title: "Rotary Rack Oven 84 Tray",
      price: "₹ 4,45,000",
      description: "We offer to our honored patrons the first-class range of 84 Tray Bakery Oven. Furthermore, our patrons can avail this product from us at affordable rates.",
    },
    {
      image: planetaryMixer,
      title: "60 Ltr plantery mixer machine",
      price: "₹ 1,10,000",
      description: "Bowl Capicity-60LtrWe, AS Machine, are enlisted amongst the renowned manufacturers of an exceptional quality collection of Bakery Oven , Bread Slicer Machine,and Mixer Machine.",
    },
    {
      image: Traybakery,
      title: "10 Tray Rotary Rack Oven",
      price: "₹ 1,95,500",
      description: "10 Tray Bakery oven is a compact, diesal fired oven with a automatic control panel. The oven is designed to accommodate a single Fixed rack of up to Ten trays capacity. ... 50 To 60 kg Per day production.",
    },
  ];

  return (
    <div className="untree_co-section product-section before-footer-section">
      <div className="container">
        <div className="row">
          {products.map((product, index) => (
            <div className="col-12 col-md-4 col-lg-3 mb-5" key={index}>
              <a className="product-item" href="#">
                <img 
                  src={product.image} 
                  className="img-fluid product-thumbnail" 
                  alt={product.title} 
                  style={{ height: '350px', objectFit: 'cover' }} 
                />
                <h3 className="product-title">{product.title}</h3>
                <strong className="product-price">{product.price}</strong>
                {renderDescription(product.description, index)}
                <a className="product-item" href="/contact">

                <span className="icon-cross">
                  <CrossImage className={"img-fluid"} />
                </span>
              </a>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default InnerService;
