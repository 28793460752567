import React from 'react';
import PlanetaryMixerBowl from '../assets/images/Service/PlanetaryMixerBowl.png';
import PotatoPeelerMachine from '../assets/images/Service/PotatoPeelerMachine.jpg';
import planetaryMixer from '../assets/images/Service/PlanetaryMixer.png';
import BakeryOven from '../assets/images/Service/BakeryOven.png';
import BreadSlicerMachine from '../assets/images/Service/BreadSlicerMachine.png';
import MixerMachine from '../assets/images/Service/MixerMachine.png';

const Categories = [
  {
    id: "bakery-oven",
    image: BakeryOven,
    title: 'Bakery Oven',
    priceRange: "₹ 32,500 - ₹ 6,10,300",
    description: 'A heavy-duty industrial oven designed for baking a variety of bread, pastries, and other baked goods. It ensures even heat distribution for consistent baking results'
  },
  {
    id: "planetary-mixer",
    image: planetaryMixer,
    title: 'Planetary Mixer',
    priceRange: "₹ 21,500 - ₹ 2,10,000",
    description: "A versatile kitchen machine used for mixing, beating, and whipping ingredients. The planetary mixing motion ensures thorough mixing of batters and doughs."
  },
  {
    id: "BreadSlicerMachine",
    image: BreadSlicerMachine,
    title: 'Bread Slicer Machine',
    priceRange: "₹ 34,500 - ₹ 45,200",
    description: 'This machine efficiently slices bread loaves into uniform pieces, saving time and ensuring consistent thickness for each slice.'
  },
  {
    id: "PlanetaryMixerBowl",
    image: PlanetaryMixerBowl,
    title: 'Planetary Mixer Bowl',
    priceRange: "₹ 28,500 - ₹ 1,58,500",
    description: 'A stainless-steel bowl specifically designed for use with a planetary mixer, providing ample capacity for mixing ingredients.'
  },
  {
    id: "MixerMachine",
    image: MixerMachine,
    title: 'Mixer Machine',
    priceRange: "₹ 1,55,000 - ₹ 3,58,500",
    description: 'A high-performance machine designed to mix a variety of ingredients, such as dough, batter, and cream, ensuring smooth and consistent results.'
  },
  {
    id: "PotatoPeelerMachine",
    image: PotatoPeelerMachine,
    title: 'Potato Peeler Machine',
    priceRange: "₹ 44,500 - ₹ 1,58,500",
    description: 'A time-saving machine used to quickly and efficiently peel large quantities of potatoes, reducing manual labor and ensuring a clean, smooth peel.'
  },
];

export default function Service() {
  return (
    <>
      <div className="hero">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="intro-excerpt">
                <h1>Our Categories</h1>
              </div>
            </div>
            <div className="col-lg-7">
              {/* Additional content can be added here if needed */}
            </div>
          </div>
        </div>
      </div>

      {/* Start Blog Section */}
      <div className="blog-section">
        <div className="container">
          <div className="row">
            {Categories.map((category, index) => (
              <div class="col-12 col-sm-6 col-md-4 mb-5" key={category.id || index}>
                <div class="post-entry">
                  <a href={`service/${category.id}`} class="post-thumbnail">
                    <img src={category.image} alt={category.title} class="img-fluid" />
                  </a>                  
                  <div class="post-content-entry">
                    <h4><a href={`service/${category.id}`}>{category.title}</a></h4>
                    <h3><a href={`service/${category.id}`}>{category.priceRange}</a></h3>
                    <div class="meta">
                      <span>{category.description} {" "}<a href={`service/${category.id}`}>More Details</a></span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Blog Section */}
    </>
  );
}
