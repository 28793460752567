import React from 'react';

export default function DotsLight() {
  return (
    <svg 
      className="dots-light"
      width="255" 
      height="217" 
      viewBox="0 0 255 217" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.15">
        {[...Array(9)].map((_, rowIndex) =>
          [...Array(9)].map((_, colIndex) => (
            <circle 
              key={`${rowIndex}-${colIndex}`} 
              cx={3.5 + colIndex * 31} 
              cy={3.5 + rowIndex * 30} 
              r="3.5" 
              fill="white" 
            />
          ))
        )}
      </g>
    </svg>
  );
}
