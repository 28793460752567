import React from 'react';
import CrossImage from '../../assets/images/Svg/cross'; 
import TopImage from '../../assets/images/top.png';
import PatatoMachine from '../../assets/images/patatomachine.png';
import SingleBreadSlicer from '../../assets/images/singlebreadslicer.png';

function ProductSection() {
  return (
    <div className="product-section">
      <div className="container">
        <div className="row">
          {/* Start Column 1 */}
          <div className="col-md-12 col-lg-3 mb-5 mb-lg-0">
            <h2 className="mb-4 section-title">Crafted Excellence: Bakery Machines Built to Last.</h2>
            <p className="mb-4">Our bakery machines are crafted with top-grade materials, ensuring durability and performance that stand the test of time. Designed for precision and efficiency, they streamline the baking process, delivering consistent, high-quality results with every batch. Built to meet the demands of modern kitchens, these machines combine craftsmanship with cutting-edge technology for flawless baking experiences.</p>
            <p><a href="/services" className="btn">Explore</a></p>
          </div>
          {/* End Column 1 */}

          {/* Start Column 2 */}
          <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
            <a className="product-item" href="/contact">
              <img src={TopImage} className="img-fluid product-thumbnail" alt="Nordic Chair" />
              <h3 className="product-title">7Ltr Plantery Mixer Steel Body Imported</h3>
              <strong className="product-price">₹ 21,500</strong>
              <span className="icon-cross">
                <CrossImage className = {"img-fluid"} />
              </span>
            </a>
          </div>
          {/* End Column 2 */}

          {/* Start Column 3 */}
          <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
            <a className="product-item" href="/contact">
              <img src={PatatoMachine} className="img-fluid product-thumbnail" alt="Semi-Automatic Potato Peeler Machine 20kg" />
              <h3 className="product-title">Semi-Automatic Potato Peeler Machine 20kg</h3>
              <strong className="product-price">₹ 44,500</strong>
              <span className="icon-cross">
                <CrossImage className= {"img-fluid"} />
              </span>
            </a>
          </div>
          {/* End Column 3 */}

          {/* Start Column 4 */}
          <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
            <a className="product-item" href="/contact">
              <img src={SingleBreadSlicer} className="img-fluid product-thumbnail" alt="Ergonomic Chair" />
              <h3 className="product-title">Single Bread Slicer Machine With SS Tray</h3>
              <strong className="product-price">₹ 34,500</strong>
              <span className="icon-cross">
                <CrossImage className = {"img-fluid"} />
              </span>
            </a>
          </div>
          {/* End Column 4 */}
        </div>
      </div>
    </div>
  );
}

export default ProductSection;
