import React, { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement form submission logic here, like API call
    console.log('Form submitted:', formData);
  };

  return (
    <>
    <section className="untree_co-section">
      <div className="container">
        <div className="block">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 pb-4">
              <div className="row mb-5">
                <div className="col-lg-4">
                  <div className="service no-shadow align-items-center link horizontal d-flex active"
                    data-aos="fade-left" data-aos-delay="0">
                    <div className="service-icon color-1 mb-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path
                          d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                      </svg>
                    </div>
                    <div className="service-contents">
                      <p> 85, Rampuri Roorkee Road, Muzaffarnagar-251001, Uttar Pradesh, India </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="service no-shadow align-items-center link horizontal d-flex active"
                    data-aos="fade-left" data-aos-delay="0">
                    <div className="service-icon color-1 mb-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                        <path
                          d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                      </svg>
                    </div>
                    <div className="service-contents">
                    <a href="mailto:sainirmi143@gmail.com" style={{textDecoration: "none"}}><p>sainirmi143@gmail.com</p></a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="service no-shadow align-items-center link horizontal d-flex active"
                    data-aos="fade-left" data-aos-delay="0">
                    <div className="service-icon color-1 mb-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                    </div>
                    <div className="service-contents">
                    <a href="tel:+919837822217" style={{textDecoration: "none"}}> <p> +91 9837822217</p></a>
                    </div>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row  mb-4">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="text-black" htmlFor="fname">First name</label>
                      <input type="text" className="form-control" id="fname" name="fname" value={formData.fname} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="text-black" htmlFor="lname">Last name</label>
                      <input type="text" className="form-control" id="lname" name="lname" value={formData.lname} onChange={handleChange} required />
                    </div>
                  </div>
                </div>
                <div className="form-group  mb-4">
                  <label className="text-black" htmlFor="email">Email address</label>
                  <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>

                <div className="form-group mb-5  mb-4">
                  <label className="text-black" htmlFor="message">Message</label>
                  <textarea className="form-control" id="message" name="message" value={formData.message} onChange={handleChange} cols="30" rows="5" required></textarea>
                </div>

                <button type="submit" className="btn btn-primary-hover-outline">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </section>
    <div className="map-container" style={{ marginTop: '20px' }}>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14385.122207320493!2d77.6854404!3d29.4744888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909ae0256c8c20b%3A0x2c2903ac5f8f1b7e!2s%2C%20Rampuri%20Roorkee%20Road%2C%20Muzaffarnagar%2C%20Uttar%20Pradesh%20251001%2C%20India!5e0!3m2!1sen!2sus!4v1633106796473!5m2!1sen!2sus"
      width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  </div>
  </>
  );
}

export default Contact;
