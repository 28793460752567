import React from 'react';

export default function Support() {
  return (
    <svg 
      width="46" 
      height="46" 
      viewBox="0 0 46 46" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M22.5 37C29.9558 37 36 30.9558 36 23.5C36 16.0442 29.9558 10 22.5 10C15.0442 10 9 16.0442 9 23.5C9 30.9558 15.0442 37 22.5 37Z" 
        stroke="black" 
        strokeWidth="2.7" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M22.5 28.9C25.4823 28.9 27.9 26.4823 27.9 23.5C27.9 20.5176 25.4823 18.1 22.5 18.1C19.5177 18.1 17.1 20.5176 17.1 23.5C17.1 26.4823 19.5177 28.9 22.5 28.9Z" 
        stroke="black" 
        strokeWidth="2.7" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M26.3205 27.3204L32.0445 33.0444" 
        stroke="black" 
        strokeWidth="2.7" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M12.9555 33.0444L18.6795 27.3204" 
        stroke="black" 
        strokeWidth="2.7" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M26.3205 19.6796L32.0445 13.9556" 
        stroke="black" 
        strokeWidth="2.7" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M26.3205 19.6796L31.086 14.9141" 
        stroke="black" 
        strokeWidth="2.7" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M12.9555 13.9556L18.6795 19.6796" 
        stroke="black" 
        strokeWidth="2.7" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
}
