import React from 'react';

function Truck() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="my-custom-class"
    >
      <path
        d="M31.6591 35.3637C33.6046 35.3637 35.1818 33.7865 35.1818 31.841C35.1818 29.8954 33.6046 28.3182 31.6591 28.3182C29.7135 28.3182 28.1364 29.8954 28.1364 31.841C28.1364 33.7865 29.7135 35.3637 31.6591 35.3637Z"
        stroke="black"
        strokeWidth="2.81818"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3409 35.3637C15.2865 35.3637 16.8636 33.7865 16.8636 31.841C16.8636 29.8954 15.2865 28.3182 13.3409 28.3182C11.3954 28.3182 9.81818 29.8954 9.81818 31.841C9.81818 33.7865 11.3954 35.3637 13.3409 35.3637Z"
        stroke="black"
        strokeWidth="2.81818"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1364 17.0454H33.7727L38 21.2727V28.3181H28.1364V17.0454Z"
        stroke="black"
        strokeWidth="2.81818"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1364 10H7V28.3182H28.1364V10Z"
        stroke="black"
        strokeWidth="2.81818"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Truck;