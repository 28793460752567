import React from 'react';

const InnovativeTechnologyIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 64 64" 
    width="28" 
    height="27"
    fill="#000000"
  >
    <path d="M32 0C14.327 0 0 14.327 0 32s14.327 32 32 32 32-14.327 32-32S49.673 0 32 0zm0 60C16.563 60 4 47.437 4 32S16.563 4 32 4s28 12.563 28 28-12.563 28-28 28zm-5-45v24.563L17.688 32 27 41.312 27 17h-5zm18 0v24.563L47.688 32 38 41.312 38 17h-5z"/>
  </svg>
);

export default InnovativeTechnologyIcon;
