import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import Navigation from './Layout/Navigation';
import Footer from './Layout/Footer';
import AppRoutes from './AppRoutes'; 

function App() {
  return (
    <div>
      <Navigation />
        <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
