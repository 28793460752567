import React from 'react';
// import whychoiceImage from '../../assets/images/why-choose-us-img.jpg'; 
import Truck from '../../assets/images/Svg/Truck';
import Bag from '../../assets/images/Svg/bag';
import Support from '../../assets/images/Svg/support';
import Return from '../../assets/images/Svg/return';
import InnovativeTechnologyIcon from '../../assets/images/Svg/InnovativeTechnologyIcon ';
import whychoiceImage from '../../assets/images/RotryRockOvens.png';

const WhyChooseUsSection = () => {
  return (
    <div className="why-choose-section">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <h2 className="section-title">Why Choose Us</h2>
            <p>We specialize in creating high-performance bakery machines that combine durability and innovative technology. Experience exceptional baking results with our commitment to quality and customer satisfaction.</p>

            <div className="row my-5">
              {[
                {
                  imgSrc: <Return/>, 
                  title: 'Top Quality',
                  description: 'We use only premium materials to ensure our bakery machines are durable and reliable.',
                },
                {
                  imgSrc: <InnovativeTechnologyIcon />, 
                  title: 'Innovative Technology',
                  description: 'Our cutting-edge machines integrate the latest technology for seamless baking processes.',
                },
                {
                  imgSrc: <Support />, 
                  title: '24/7 Support',
                  description: 'Our dedicated support team is available around the clock to assist you with any inquiries or needs.',
                },
                {
                  imgSrc: <Truck />, 
                  title: 'Fast Delivery:',
                  description: 'Enjoy prompt delivery of your bakery machines, ensuring you can start baking without delay.',
                },
              ].map((feature, index) => (
                <div key={index} className="col-6 col-md-6">
                  <div className="feature">
                    <div className="icon">
                      {feature.imgSrc} {/* Render the icon directly */}
                    </div>
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-lg-5">
            <div className="img-wrap">
            <img src={whychoiceImage} alt="Image" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUsSection;
