import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import PlanetaryMixer1 from '../../../assets/images/Service/PlanetaryMixer/1.webp';
import PlanetaryMixer2 from '../../../assets/images/Service/PlanetaryMixer/2.webp';
import PlanetaryMixer3 from '../../../assets/images/Service/PlanetaryMixer/3.webp';
import PlanetaryMixer4 from '../../../assets/images/Service/PlanetaryMixer/4.webp';
import LtrPlantery1 from '../../../assets/images/Service/PlanetaryMixer/7LtrPlantery1.webp';
import LtrPlantery2 from '../../../assets/images/Service/PlanetaryMixer/7LtrPlantery2.webp';
import SpiralMixer1 from '../../../assets/images/Service/PlanetaryMixer/SpiralMixer1.webp';
import SpiralMixer2 from '../../../assets/images/Service/PlanetaryMixer/Spiral Mixer2.webp';



function PlanetaryMixer() {
    const [selectedImages, setSelectedImages] = useState({}); // Store selected images for each oven

    const handleImageClick = (id, image) => {
        setSelectedImages((prev) => ({ ...prev, [id]: image }));
    };

    const AllOvens = [
        {
            id: "1",
            images: [ PlanetaryMixer1, PlanetaryMixer2, PlanetaryMixer3, PlanetaryMixer4],
            productDetails: {
                name: "60 Ltr plantery mixer machine",
                Material: "Stainless Steel (SS)",
                price: "₹ 1,10,000",
                BowlVolume	: "60 litre",
                description: "Bowl Capicity-60LtrWe, AS Machine, are enlisted amongst the renowned manufacturers of an exceptional quality collection of Bakery Oven , Bread Slicer Machine,and Mixer Machine. The whole provided range of products is manufactured by our adroit professionals utilizing supreme quality components and upgraded techniques. Furthermore, these products are strictly examined against varied parameters of quality to ensure the delivery of fault free products.",
                AutomationGrade	: "Semi-Automatic",
                PowerSource: "Electric",
                UsageApplication: "Commercial",
                Capacity: "60 LTR",
                UsedforMaking	: "Biscuit/Cookies",
                MotorPowerinHP	: "2-3 HP",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        },
        {
            id: "2",
            images: [ LtrPlantery1, LtrPlantery2],
            productDetails: {
                name: "Single 7Ltr Plantery Mixer Steel Body Imported",
                price: "₹ 21,500",
                Material: "Stainless Steel",
                BowlVolume	: "07 Ltr",
                MixerType	: "Single",
                description: "07 Ltr BWe, AS Machine, are enlisted amongst the renowned manufacturers of an exceptional quality collection of Bakery Oven , Bread Slicer Machine,and Mixer Machine. The whole provided range of products is manufactured by our adroit professionals utilizing supreme quality components and upgraded techniques. Furthermore, these products are strictly examined against varied parameters of quality to ensure the delivery of fault free products.owl Capicity.Full body stanless Steel",
                AutomationGrade	: "Semi-Automatic",
                PowerSource: "Electric",
                UsageApplication: "Commercial / Large",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        },
        {
            id: "3",
            images: [ SpiralMixer1, SpiralMixer2],
            productDetails: {
                name: "50 Kg Spiral Mixer Machine",
                price: "₹ 2,10,000",
                Material: "Stainless Steel (SS)",
                BowlType: "Fixed Bowl",
                BowlVolume	: "50kg",
                MixerType	: "Single",
                description: "",
                AutomationGrade	: "Automatic",
                PowerSource: "Electric",
                Capacity: "50 kg",
                UsedforMaking	: "Bread",
                Warranty:"1 Year",
                ModesofSpeed: "Three",
                IDealIn	: "New and Second Hand",
                UsageApplication: "Bakery",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        },


    ];

    return (
        <div className="untree_co-section before-footer-section">
            <div className="container">
                {AllOvens.map((AllOven) => {
                    const { id, images, productDetails } = AllOven;
                    const selectedImage = selectedImages[id] || images[0];

                    return (
                        <div key={id} className="row mb-5">
                            <div className="col-md-6 left-side">
                                <div className="image-layout">
                                    <Swiper
                                        direction="vertical"
                                        spaceBetween={10}
                                        slidesPerView={Math.min(AllOven.images.length, 5)}
                                        loop={true}
                                        className="image-slider"
                                        breakpoints={{
                                            768: {
                                                direction: 'vertical',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            576: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            0: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                        }}
                                    >
                                        {images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={image}
                                                    alt={`Oven ${index + 1}`}
                                                    className="small-image"
                                                    onClick={() => handleImageClick(id, image)}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="big-image-container">
                                        <img src={selectedImage} alt="Selected Bakery Oven" className="big-image" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 right-side">
                                <h2>{productDetails.name}</h2>
                                <hr />
                                <div className="row mt-3 productDetails">
                                    <div className="col-md-6">
                                        {productDetails?.price && (
                                            <p><strong>Price:</strong> {productDetails.price}</p>
                                        )}
                                        {productDetails?.Material && (
                                            <p><strong>Material:</strong> {productDetails.Material}</p>
                                        )}
                                        
                                        {productDetails?.BowlType && (
                                            <p><strong>Bowl Type	:</strong> {productDetails.BowlType}</p>
                                        )}
                                        {productDetails?.BowlVolume && (
                                            <p><strong>Bowl Volume	:</strong> {productDetails.BowlVolume}</p>
                                        )}
                                        {productDetails?.PowerSource && (
                                            <p><strong>Power Source:</strong> {productDetails.PowerSource}</p>
                                        )}
                                        {productDetails?.UsageApplication && (
                                            <p><strong>Usage Application:</strong> {productDetails.UsageApplication}</p>
                                        )}
                                        {productDetails?.Capacity && (
                                            <p><strong>Capacity:</strong> {productDetails.Capacity}</p>
                                        )}
                                         {productDetails?.IDealIn && (
                                            <p><strong>I Deal In:</strong> {productDetails.IDealIn}</p>
                                        )}
                                        
                                    </div>
                                    <div className="col-md-6">
                                        {productDetails?.AutomationGrade && (
                                            <p><strong>Automation Grade:</strong> {productDetails.AutomationGrade}</p>
                                        )}
                                         {productDetails?.MixerType && (
                                            <p><strong>Mixer Type:</strong> {productDetails.MixerType}</p>
                                        )}
                                        {productDetails?.UsedforMaking && (
                                            <p><strong>Used for Making:</strong> {productDetails.UsedforMaking}</p>
                                        )}
                                        {productDetails?.MotorPowerinHP && (
                                            <p><strong>Motor Power(in HP):</strong> {productDetails.MotorPowerinHP}</p>
                                        )}
                                        {productDetails?.ModesofSpeed && (
                                            <p><strong>Modes of Speed:</strong> {productDetails.ModesofSpeed}</p>
                                        )}
                                         {productDetails?.Warranty && (
                                            <p><strong>Warranty:</strong> {productDetails.Warranty}</p>
                                        )}
                                        {productDetails?.Brand && (
                                            <p><strong>Brand:</strong> {productDetails.Brand}</p>
                                        )}
                                        {productDetails?.CountryofOrigin && (
                                            <p><strong>Country of Origin:</strong> {productDetails.CountryofOrigin}</p>
                                        )}
                                    </div>
                                    {productDetails?.description && (
                                        <p><strong>Description:</strong> {productDetails.description}</p>
                                    )}
                                </div>
                                <hr />
                                <div className="contact-section mt-1 text-end">
                                    <a href="tel:+9837822217" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-phone me-2"></i>Call Us: 9837822217
                                    </a>
                                    <a href="mailto:sainirmi143@gmail.com" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-envelope me-2"></i>sainirmi143@gmail.com
                                    </a>
                                    <a href='/contact' className='btn btn-primary me-2'>Contact Us</a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}


export default PlanetaryMixer;