import React from 'react';
import EnvelopeOutline from '../assets/images/Svg/EnvelopeOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faMapMarkerAlt, faPhone, faEnvelope, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Planterymixermachine from '../assets/images/top.png';
import logoImage from '../assets/images/logo.png';

export default function Footer() {
  return (
    <div>
      <footer className="footer-section">
        <div className="container relative">
          <div className="sofa-img">
            <img src={logoImage} alt="Image" className="img-fluid" />
          </div>

          <div className="row">
            <div className="col-lg-8">
              <div className="subscription-form">
                <h3 className="d-flex align-items-center">
                  <span className="me-1">
                    <EnvelopeOutline className={"img-fluid"} />
                  </span>
                  <span>Subscribe to Newsletter</span>
                </h3>

                <form action="#" className="row g-3">
                  <div className="col-auto">
                    <input type="text" className="form-control" placeholder="Enter your name" />
                  </div>
                  <div className="col-auto">
                    <input type="email" className="form-control" placeholder="Enter your email" />
                  </div>
                  <div className="col-auto">
                    <button className="btn btn-primary" type="submit">
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row g-5 mb-5">
            <div className="col-lg-4">
              <div className="mb-4 footer-logo-wrap">
                <a href="#" className="footer-logo">AS Machines<span>.</span></a>
              </div>
              <p className="mb-4">
                AS Machines crafts innovative bakery solutions to elevate your baking experience. Trust in our machines for quality,
                efficiency, and exceptional results
              </p>

              <ul className="list-unstyled custom-social">
                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
                <li><a href="#"><FontAwesomeIcon icon={faLinkedin} /></a></li>
              </ul>
            </div>

            <div className="col-lg-8">
              <div className="row links-wrap">
                <div className="col-6 col-sm-6 col-md-3">
                  <ul className="list-unstyled">
                    <li><a href="about">About us</a></li>
                    <li><a href="/services">Category</a></li>
                    <li><a href="/products">Our Products</a></li>
                    <li><a href="/contact">Contact us</a></li>
                  </ul>
                </div>

                <div className="col-6 col-sm-6 col-md-3">
                  <ul className="list-unstyled">
                    <li><a href="/service/bakery-oven">Bakery Oven</a></li>
                    <li><a href="/service/planetary-mixer">Planetary Mixer</a></li>
                    <li><a href="/service/PlanetaryMixerBowl">Planetary Mixer Bowl</a></li>
                    <li><a href="/service/BreadSlicerMachine">Bread Slicer Machine</a></li>
                    {/* <li><a href="#">Mixer Machine</a></li> */}
                    <li><a href="/service/PotatoPeelerMachine">Potato Peeler Machine</a></li>
                  </ul>
                </div>

                {/* <div className="col-6 col-sm-6 col-md-3">
                  <ul className="list-unstyled">
                    
                  </ul>
                </div> */}

                <div className="col-12 col-sm-6 col-md-6">
                  <ul className="list-unstyled">
                    <li>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />  85, Rampuri Roorkee Road, Muzaffarnagar-251001, Uttar Pradesh, India
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faPhone} />
                      <a href="tel:+919837822217">  +91 9837822217</a>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <a href="mailto:sainirmi143@gmail.com"> sainirmi143@gmail.com</a>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faFileAlt} /> GST No: 09BHDPK1743H1ZF
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>

          <div className="border-top copyright">
            <div className="row pt-4">
              <div className="col-lg-6">
                <p className="mb-2 text-center text-lg-start">
                  Copyright &copy; {new Date().getFullYear()}. All Rights Reserved. &mdash; Crafted with precision by
                  <a href="/"> AS Machines</a> Distributed by Bijendra Saini.
                </p>
              </div>

              {/* <div className="col-lg-6 text-center text-lg-end"> */}
              {/* <ul className="list-unstyled d-inline-flex ms-auto">
                  <li className="me-4"><a href="#">Terms &amp; Conditions</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                </ul> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
