import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom'; 
import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service';
import Contact from './pages/Contact';
import Product from './pages/Product';
import BakeryOven from './pages/Component/Category/BakeryOven';
import PlanetaryMixer from './pages/Component/Category/PlanetaryMixer';
import BreadSlicerMachine from './pages/Component/Category/BreadSlicerMachine';
import PlanetaryMixerBowl from './pages/Component/Category/PlanetaryMixerBowl';
import MixerMachine from './pages/Component/Category/MixerMachine';
import PotatoPeelerMachine from './pages/Component/Category/PotatoPeelerMachine';


function AppRoutes() {
  return (
    <Suspense fallback={<div>Loading...</div>}> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/service/bakery-oven" element={<BakeryOven />} /> 
        <Route path="/service/planetary-mixer" element={<PlanetaryMixer />} />
        <Route path="/service/BreadSlicerMachine" element={<BreadSlicerMachine />} />
        <Route path="/service/PlanetaryMixerBowl" element={<PlanetaryMixerBowl />} />
        <Route path="/service/PotatoPeelerMachine" element={<PotatoPeelerMachine />} />
        
        <Route path="/service/MixerMachine" element={<MixerMachine />} />
        <Route path="/products" element={<Product />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<div>404 - Page Not Found</div>} /> 
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
