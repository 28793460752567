import React from 'react';
import card from '../assets/images/About/card.jpg';
import About1 from '../assets/images/About/3.jpg';
import About2 from '../assets/images/About/4.jpg';

function About() {
  return (
    <div className="untree_co-section product-section before-footer-section">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="row img-twice position-relative h-100">
              <div className="col-6">
                <img className="img-fluid rounded" src={About2} alt="Baking Process" />
              </div>
              <div className="col-6 align-self-end">
                <img className="img-fluid rounded" src={About1} alt="Baked Goods" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="h-100">
              <p className="text-primary text-uppercase mb-2"> <b>About Us</b></p><p>
                We, <b>AS Machine </b>, are enlisted amongst the renowned manufacturers of an exceptional quality collection of Bakery Oven , Bread Slicer Machine,and Mixer Machine. The whole provided range of products is manufactured by our adroit professionals utilizing supreme quality components and upgraded techniques. Furthermore, these products are strictly examined against varied parameters of quality to ensure the delivery of fault free products.
              </p>
              <p>
                Under the supervision of our mentor, <b>Mr. Bijendar Kumar (Proprietor)</b>, our organization has gained huge admiration from our patrons.
              </p>
              <div className="row g-2 mb-4">
                <div className="col-sm-6">
                  <i className="fa fa-check me-2"></i>Quality Products
                </div>
                <div className="col-sm-6">
                  <i className="fa fa-check me-2"></i>Custom Products
                </div>
                <div className="col-sm-6">
                  <i className="fa fa-check me-2"></i>Online Order
                </div>
                <div className="col-sm-6">
                  <i className="fa fa-check me-2"></i>Home Delivery
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 about_basicInfo" data-wow-delay="0.5s">
        <hr />
          <div className="h-100">
            <h2 className="display-10 mb-4">Basic Information</h2>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Information</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Nature of Business</td>
                  <td>Manufacturer</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Company CEO</td>
                  <td>Bijendar Kumar</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Year of Establishment</td>
                  <td>2010</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Legal Status of Firm</td>
                  <td>Individual - Proprietor</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Total Number of Employees	</td>
                  <td>Upto 10 People</td>
                </tr>
              </tbody>
            </table>            
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
