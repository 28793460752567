import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import breadSlicer1 from '../../../assets/images/Service/breadSlicer/1.webp';
import double1 from '../../../assets/images/Service/breadSlicer/double1.webp';
import double2 from '../../../assets/images/Service/breadSlicer/double2.webp';
import double3 from '../../../assets/images/Service/breadSlicer/double3.webp';



function BreadSlicerMachine() {
    const [selectedImages, setSelectedImages] = useState({}); 

    const handleImageClick = (id, image) => {
        setSelectedImages((prev) => ({ ...prev, [id]: image }));
    };

    const AllOvens = [
        {
            id: "1",
            images: [breadSlicer1],
            productDetails: {
                name: "Single Bread Slicer Machine With SS Tray",
                price: "₹ 34,500",
                OperationMode: "Stainless Steel (SS)",
                CuttingThickness: "12 mm",
                description: "The bread slicer is an equipment designed to cut bakery products already prepared (cooked) into several slices automatically, replacing the manual use of kitchen utensils. It consists of: Spaced blades in several standard dimensions, proceeding to a graph cut. The cutting process is triggered by a side lever.",
                NumberOfBlades: "Semi-Automatic",
                Power: "0.37kw",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
                AdditionalInformation: "Production Capacity: 50 kg per Hour"
            }
        },
        {
            id: "2",
            images: [double1, double2, double3],
            productDetails: {
                name: "Double Bread Slicer Machine",
                price: "₹ 45,200",
                OperationMode: "Automatic",
                CuttingThickness: "5 mm",
                description: "The bread slicer is an equipment designed to cut bakery products already prepared (cooked) into several slices automatically, replacing the manual use of kitchen utensils.",
                PowerSource: "Electric",
                Voltage: "220",
                AutomationGrade: "Semi-Automatic",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
                AdditionalInformation: "Production Capacity: 70kg per hour and Delivery Time: 7 Days"
            }
        },
    ];

    return (
        <div className="untree_co-section before-footer-section">
            <div className="container">
                {AllOvens.map((AllOven) => {
                    const { id, images, productDetails } = AllOven;
                    const selectedImage = selectedImages[id] || images[0];

                    return (
                        <div key={id} className="row mb-5">
                            <div className="col-md-6 left-side">
                                <div className="image-layout">
                                    <Swiper
                                        direction="vertical"
                                        spaceBetween={10}
                                        slidesPerView={Math.min(AllOven.images.length, 5)}
                                        loop={true}
                                        className="image-slider"
                                        breakpoints={{
                                            768: {
                                                direction: 'vertical',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            576: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            0: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                        }}
                                    >
                                        {images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={image}
                                                    alt={`Oven ${index + 1}`}
                                                    className="small-image"
                                                    onClick={() => handleImageClick(id, image)}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="big-image-container">
                                        <img src={selectedImage} alt="Selected Bakery Oven" className="big-image" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 right-side">
                                <h2>{productDetails.name}</h2>
                                <hr />
                                <div className="row mt-3 productDetails">
                                    <div className="col-md-6">
                                        {productDetails?.price && (
                                            <p><strong>Price:</strong> {productDetails.price}</p>
                                        )}
                                        {productDetails?.OperationMode && (
                                            <p><strong>Operation Mode:</strong> {productDetails.OperationMode}</p>
                                        )}

                                        {productDetails?.CuttingThickness && (
                                            <p><strong>Cutting Thickness	:</strong> {productDetails.CuttingThickness}</p>
                                        )}
                                        {productDetails?.NumberOfBlades && (
                                            <p><strong>Number Of Blades	:</strong> {productDetails.NumberOfBlades}</p>
                                        )}
                                         {productDetails?.Voltage && (
                                            <p><strong>Voltage	:</strong> {productDetails.Voltage}</p>
                                        )}

                                    </div>
                                    <div className="col-md-6">
                                        {productDetails?.PowerSource && (
                                            <p><strong>Power Source :</strong> {productDetails.PowerSource}</p>
                                        )}
                                        {productDetails?.Power && (
                                            <p><strong>Power :</strong> {productDetails.Power}</p>
                                        )}
                                         {productDetails?.AutomationGrade && (
                                            <p><strong>Automation Grade :</strong> {productDetails.AutomationGrade}</p>
                                        )}
                                        {productDetails?.Brand && (
                                            <p><strong>Brand:</strong> {productDetails.Brand}</p>
                                        )}
                                        {productDetails?.CountryofOrigin && (
                                            <p><strong>Country of Origin:</strong> {productDetails.CountryofOrigin}</p>
                                        )}
                                    </div>
                                    {productDetails?.description && (
                                        <p><strong>Description:</strong> {productDetails.description}</p>
                                    )}

                                    {productDetails?.AdditionalInformation && (
                                        <p><strong>Additional Information:</strong> {productDetails.AdditionalInformation}</p>
                                    )}

                                </div>
                                <hr />
                                <div className="contact-section mt-1 text-end">
                                    <a href="tel:+9837822217" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-phone me-2"></i>Call Us: 9837822217
                                    </a>
                                    <a href="mailto:sainirmi143@gmail.com" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-envelope me-2"></i>sainirmi143@gmail.com
                                    </a>
                                    <a href='/contact' className='btn btn-primary me-2'>Contact Us</a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default BreadSlicerMachine;
