import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import SpiralMixer1 from '../../../assets/images/Service/SpiralMixer/1.webp';
import SpiralMixer2 from '../../../assets/images/Service/SpiralMixer/2.webp';



function MixerMachine() {
    const [selectedImages, setSelectedImages] = useState({});

    const handleImageClick = (id, image) => {
        setSelectedImages((prev) => ({ ...prev, [id]: image }));
    };


    const AllOvens = [
        {
            id: "1",
            images: [SpiralMixer1, SpiralMixer2],
            productDetails: {
                name: "30 Kg Spiral Mixer",
                price: "₹ 1,55,000",
                BowlType: "Fixed Bowl",
                BowlVolume: "30 Ltr",
                UsageApplication: "Bakery",
                PowerSource: "Electric",
                AutomationGrade: "Automatic",
                DesignType: "Standard",
                Capacity: "30 kg",
                UsedforMaking: "Bread",
                ModesofSpeed: "Three",
                IDealIn: "New and Second Hand",
                FlourCapacity: "30kg",
                Condition: "New",
                description: "A.S Machine Spiral Mixer Are Economical in Price then a Comparable of other Spiral Mixer,Its CAN save as much 30% or more of the Elect. Coasts of other daugh mixer its clect.coast and maint. is very low",
                Warranty: "1 Year",
                Brand: "A.S. Machine",
                CountryofOrigin: "Made in India",
            }
        },
    ];

    return (
        <div className="untree_co-section before-footer-section">
            <div className="container">
                {AllOvens.map((AllOven) => {
                    const { id, images, productDetails } = AllOven;
                    const selectedImage = selectedImages[id] || images[0];

                    return (
                        <div key={id} className="row mb-5">
                            <div className="col-md-6 left-side">
                                <div className="image-layout">
                                    <Swiper
                                        direction="vertical"
                                        spaceBetween={10}
                                        slidesPerView={Math.min(AllOven.images.length, 5)}
                                        loop={true}
                                        className="image-slider"
                                        breakpoints={{
                                            768: {
                                                direction: 'vertical',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            576: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                            0: {
                                                direction: 'horizontal',
                                                slidesPerView: Math.min(AllOven.images.length, 5),
                                            },
                                        }}
                                    >
                                        {images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={image}
                                                    alt={`Oven ${index + 1}`}
                                                    className="small-image"
                                                    onClick={() => handleImageClick(id, image)}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="big-image-container">
                                        <img src={selectedImage} alt="Selected Bakery Oven" className="big-image" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 right-side">
                                <h2>{productDetails.name}</h2>
                                <hr />
                                <div className="row mt-3 productDetails">
                                    <div className="col-md-6">
                                        {productDetails?.price && (
                                            <p><strong>Price:</strong> {productDetails.price}</p>
                                        )}
                                        {productDetails?.BowlType && (
                                            <p><strong>Bowl Type:</strong> {productDetails.BowlType}</p>
                                        )}
                                        {productDetails?.BowlVolume && (
                                            <p><strong>Bowl Volume	:</strong> {productDetails.BowlVolume}</p>
                                        )}
                                        {productDetails?.UsageApplication && (
                                            <p><strong>Usage Application	:</strong> {productDetails.UsageApplication}</p>
                                        )}
                                        {productDetails?.Capacity && (
                                            <p><strong>Capacity :</strong> {productDetails.Capacity}</p>
                                        )}
                                        {productDetails?.FlourCapacity && (
                                            <p><strong>Flour Capacity	:</strong> {productDetails.FlourCapacity}</p>
                                        )}
                                        {productDetails?.ModesofSpeed && (
                                            <p><strong>Modes of Speed	:</strong> {productDetails.ModesofSpeed}</p>
                                        )}
                                        {productDetails?.IDealIn && (
                                            <p><strong>I Deal In	:</strong> {productDetails.IDealIn}</p>
                                        )}


                                    </div>
                                    <div className="col-md-6">
                                        {productDetails?.PowerSource && (
                                            <p><strong>Power Source :</strong> {productDetails.PowerSource}</p>
                                        )}

                                        {productDetails?.AutomationGrade && (
                                            <p><strong>Automation Grade :</strong> {productDetails.AutomationGrade}</p>
                                        )}
                                        {productDetails?.UsedforMaking && (
                                            <p><strong>Used for Making :</strong> {productDetails.UsedforMaking}</p>
                                        )}
                                        {productDetails?.DesignType && (
                                            <p><strong>Design Type :</strong> {productDetails.DesignType}</p>
                                        )}

                                        {productDetails?.Warranty && (
                                            <p><strong>Warranty :</strong> {productDetails.Warranty}</p>
                                        )}

                                        {productDetails?.Brand && (
                                            <p><strong>Brand:</strong> {productDetails.Brand}</p>
                                        )}
                                        {productDetails?.CountryofOrigin && (
                                            <p><strong>Country of Origin:</strong> {productDetails.CountryofOrigin}</p>
                                        )}
                                    </div>
                                    {productDetails?.description && (
                                        <p><strong>Description:</strong> {productDetails.description}</p>
                                    )}

                                </div>
                                <hr />
                                <div className="contact-section mt-1 text-end">
                                    <a href="tel:+9837822217" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-phone me-2"></i>Call Us: 9837822217
                                    </a>
                                    <a href="mailto:sainirmi143@gmail.com" className="text-primary ms-3" style={{ textDecoration: "none" }}>
                                        <i className="fa fa-envelope me-2"></i>sainirmi143@gmail.com
                                    </a>
                                    <a href='/contact' className='btn btn-primary me-2'>Contact Us</a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default MixerMachine;